<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-container fluid id="iiotUsageReports" data-cy="iiotUsageReports" class="pt-0">
    <the-nerve-table
      id="iiotUsageReportsList"
      :columns="columns"
      :empty-state="$t('usageReports.list.emptyList')"
      :is-action-menu-enabled="false"
      :disable-pagination="true"
      :hide-footer="true"
      :is-row-clickable="false"
      store-module="usage-reports"
      data-cy="iiotUsageReportsList"
      @params-changed="(params) => changedParams(params)"
    >
      <template #additional-actions>
        <current-month-download-btn />
      </template>
    </the-nerve-table>

    <notify-tttech-switch />
    <gpg-public-key-download-btn />
  </v-container>
</template>

<script>
import { TheNerveTable } from 'nerve-ui-components';
import NotifyTttechSwitch from '@/components/usage-reports/helpers/NotifyTTTechSwitch.vue';
import CurrentMonthDownloadBtn from '@/components/usage-reports/helpers/CurrentMonthDownloadBtn.vue';
import GpgPublicKeyDownloadBtn from '@/components/usage-reports/helpers/GpgPublicKeyDownloadBtn.vue';

export default {
  components: {
    TheNerveTable,
    NotifyTttechSwitch,
    CurrentMonthDownloadBtn,
    GpgPublicKeyDownloadBtn,
  },
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      params: {},
    };
  },
  computed: {
    columns() {
      return [
        {
          text: this.$t('globals.month'),
          value: 'month',
          sortable: false,
        },
        {
          text: this.$t('globals.year'),
          value: 'year',
          sortable: false,
        },
        {
          text: '',
          value: 'icon',
          sortable: false,
          component: {
            sfc: () => import('@/components/usage-reports/helpers/DownloadIcon.vue'),
          },
        },
      ];
    },
  },
  created() {
    this.params = {
      search: this.search,
    };
  },
  methods: {
    changedParams(params) {
      this.params = params;
      this.$router
        .push({
          name: 'System info',
          query: {
            tab: 1,
            search: params.search || undefined,
          },
        })
        .catch(() => {});
    },
  },
};
</script>
