<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-container fluid class="pl-6 pr-6">
    <v-tabs v-model="tab" class="pb-6">
      <v-tab
        v-if="canAccess('VERSION:LIST')"
        id="iiotSystemInfoTabAvailableUpdates"
        :key="availableUpdates"
        data-cy="iiotSystemInfoTabAvailableUpdates"
        autofocus
        @click="tabChanged(0)"
        style="font-weight: bold"
      >
        {{ $t('availableUpdates.list.title') }}
      </v-tab>
      <v-tab
        v-if="canAccess('UI_USAGE_REPORT:VIEW')"
        id="iiotSystemInfoTabUsageReports"
        :key="usageReports"
        data-cy="iiotSystemInfoTabUsageReports"
        @click="tabChanged(1)"
        style="font-weight: bold"
      >
        {{ $t('usageReports.list.title') }}
      </v-tab>
      <v-tab
        v-if="canAccess('UI_SERVER_AUDIT:VIEW') || canAccess('UI_SERVER_LOGS:VIEW')"
        id="iiotSystemInfoTabLogs"
        data-cy="iiotSystemInfoTabLogs"
        :key="logs"
        @click="tabChanged(2)"
        style="font-weight: bold"
      >
        {{ $t('auditLogs.list.title') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-if="canAccess('VERSION:LIST')" :key="availableUpdates">
        <available-updates-list />
      </v-tab-item>
      <v-tab-item v-if="canAccess('UI_USAGE_REPORT:VIEW')" :key="usageReports">
        <usage-reports :search="search" />
      </v-tab-item>
      <v-tab-item v-if="canAccess('UI_SERVER_AUDIT:VIEW') || canAccess('UI_SERVER_LOGS:VIEW')" :key="logs">
        <audit-logs />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import UsageReports from '@/components/usage-reports/UsageReports.vue';
import AvailableUpdatesList from '../components/available-updates/AvailableUpdatesList.vue';
import AuditLogs from '@/components/audit-logs/AuditLogs.vue';

export default {
  components: {
    UsageReports,
    AvailableUpdatesList,
    AuditLogs,
  },
  props: ['search'],
  data: () => ({
    tab: null,
    availableUpdates: 0,
    usageReports: 1,
    logs: 2,
  }),
  beforeMount() {
    // eslint-disable-next-line no-restricted-globals
    this.tab = Number(new URLSearchParams(location.search).get('tab'));
  },
  mounted() {
    this.tabChanged(this.tab);
  },
  methods: {
    async tabChanged(tab) {
      await this.$router
        .push({
          name: 'System info',
          query: {
            tab,
          },
        })
        .catch(() => {});
    },
  },
};
</script>
